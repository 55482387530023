import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, SimpleChanges} from '@angular/core';
import {map} from 'rxjs/operators';
import {SynchronizationDirective} from './synchronization.directive';


@Component({
  selector: 'app-pims-sync-widget',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './synchronization.component.html',
  styleUrls: ['./synchronization.component.css']
})
export class PimsSynchronizationComponent extends SynchronizationDirective implements OnChanges {
  @Input() employeeId: number;
  @Input() locationId: number;

  ngOnChanges(changes: SimpleChanges): void {
    this.employeeId = changes.employeeId.currentValue as number;
    if (this.employeeId) {
      this.init()
    }
  }

  private init() {
    this.synchronizationService.getEmployeePimsSyncStatus(this.employeeId, this.locationId, 'VETSOURCE')
      .pipe(map(items => items.map(item => this.convert(item)))).subscribe(t => {
        this.rows.next(t);
        }, error => {
        console.log(error);
      });
  }
}
