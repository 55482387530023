import {Component, Input, OnDestroy, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {MatTable} from '@angular/material/table';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DetailsDataService} from '../details.data.service';
import {MasterEmployeeService} from '../../master.employee.service';
import {EmployeeDetails, Location} from '../../master.entities.models';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'pims-accounts-tab',
  templateUrl: './pims-accounts.component.html',
  styleUrls: ['./pims-accounts.component.css']
})
export class PimsAccountsComponent implements OnDestroy {
  displayedColumns: string[] = ['id', 'name'];
  data: Location[] = [];
  subscription;
  isLoadingResults = true;
  pageSize = 25;

  @Input() employee: EmployeeDetails;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<any>;

  constructor(public route: ActivatedRoute,
              public router: Router,
              public masterEmployeeService: MasterEmployeeService,
              public dialog: MatDialog,
              private _snackBar: MatSnackBar,
              private detailsDataService: DetailsDataService) {
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnInit() {
    this.subscription = this.detailsDataService.currentMessage.subscribe((msg: any) => {
      if (msg && msg.id && msg.textLabel === 'PIMS Accounts') {
        this.subscribe(msg.id);
      }
    });
  }

  subscribe(id) {
    this.isLoadingResults = true;
    // tslint:disable-next-line:max-line-length
    return this.masterEmployeeService.getLocations(id)
      .subscribe(data => {
        this.isLoadingResults = false;
        // @ts-ignore
        this.data = data;
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
