<!--Begin Employee Profile -->
<h5 class="card-title text-muted" style="padding-top: 15px">
  <a routerLink=".." style="border: none !important;">..</a>/{{fullName}} ({{id}})
</h5>
<mat-divider></mat-divider>
<!--<h5 *ngIf="action=='add'" class="card-title text-muted">New employee ({{rarebreedEmployeeId}})</h5>-->
<mat-tab-group mat-stretch-tabs (selectedTabChange)="onDetailsTabsClick($event, id)">
  <mat-tab label="Personal Information">
    <personal-tab [personalInfo]='employeeDetails' (personalInfoUpdate)="onUpdate($event)"></personal-tab>
  </mat-tab>
  <mat-tab label="Employments ({{employments.length}})">
    <app-employments-tab [employments]="employments"
                         [employee]="employeeDetails"
                         (employmentTabEventEmitter)="onEmploymentTabEvent($event)"></app-employments-tab>
  </mat-tab>
<!--  <mat-tab label="Employments">
    <app-employments></app-employments>
  </mat-tab>-->
  <mat-tab label="Tax" [disabled]="employments.length === 0">
    <app-tax-tab [employments]="employments" (taxTabEventEmitter)="onTaxTabEvent($event)"></app-tax-tab>
  </mat-tab>
  <mat-tab label="PIMS Accounts">
    <pims-accounts-tab [employee]="employeeDetails"></pims-accounts-tab>
  </mat-tab>
  <mat-tab label="Change History">
    <change-history-tab [virtual]="false"></change-history-tab>
  </mat-tab>
</mat-tab-group>


